<template>
    <div class="promotion-content">
        <div class="left-box">
            <div class="top-box">
                <i class="iconfont">&#xe68d;</i>
                <div class="text">京东直投</div>
            </div>
            <el-menu :default-active="menuActive" mode="vertical" class="left-menu">
                <el-menu-item index="studentJDDirectInvestmentList">
                    <div class="nav-item" @click="toPage('/student/JDDirectInvestment/list')">
                        <i class="iconfont">&#xe646;</i>推广管理
                    </div>
                </el-menu-item>
            </el-menu>
        </div>
        <div class="main-box">
            <div class="header-box">
                <div class="time-out" v-if="isExamOrTrain">
                    <CountDown :timeObj="timeObj" />
                </div>
                <div class="header-right">
                    <div class="user-info">
                        <div class="avatar">
                            <img :src="userInfo.avatar" alt="">
                        </div>
                        <div class="user-name">{{userInfo.name}}</div>
                    </div>
                    <div class="back-btn" @click="backBtn">
                        <i class="iconfont">&#xe644;</i>返回主界面
                    </div>
                </div>
            </div>
            <div class="content-box">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
    import { examStuExamPage } from '@/utils/apis.js'
    import CountDown from '@/components/student/CountDown.vue'
    export default {
        name: "JDDirectInvestmentLayout",
        components: {
            CountDown
        },
        data() {
            return {
                menuActive: '',
                timeObj: {
                    begin_time: null,
                    end_time: null,
                },
                userInfo: {
                    avatar: localStorage.getItem('studentAvatar') ? localStorage.getItem('studentAvatar') : require('../../assets/images/user_img.png'),
                    name: localStorage.getItem('studentName'),
                },
                isExamOrTrain: localStorage.getItem('examId') ? localStorage.getItem('examId') : null
            }
        },
        watch: {
            $route(route) {
                this.menuActive = route.matched[1].name;
                if (route.matched[1].name === 'studentJDDirectInvestmentIndex') {
                    this.menuActive = 'studentJDDirectInvestmentList'
                }
            },
        },
        created() {
            this.menuActive = this.$route.matched[1].name;
            if (this.$route.matched[1].name === 'studentJDDirectInvestmentIndex') {
                this.menuActive = 'studentJDDirectInvestmentList'
            }
            if (this.isExamOrTrain) {
                this.getExamStuExamPage()
            }
        },
        methods: {
            // 菜单跳转页面
            toPage(link) {
                this.$router.push({
                    path: link,
                    query: {
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id
                    }
                })
            },
            // 获取考试信息
            getExamStuExamPage(){
                examStuExamPage().then((res) => {
                    this.timeObj.begin_time = res.data.begin_time;
                    this.timeObj.end_time = res.data.end_time;
                }).catch((err) => {})
            },
            // 返回主界面
            backBtn() {
                if (this.isExamOrTrain) {
                    this.$router.push('/student/exam/internetMarketing/index')
                } else {
                    this.$router.push('/student/trainCenter/practice')
                }
                // localStorage.removeItem('infoStepTwo')
            }
        }
    }
</script>

<style scoped lang="scss">
    .promotion-content {
        width: 100vw;
        height: 100vh;
        display: flex;
    }
    .left-box {
        width: 280px;
        background: #2C405F;
        height: 100%;
        .top-box {
            color: #fff;
            text-align: center;
            margin-top: 36px;
            .iconfont {
                display: inline-block;
                width: 60px;
                height: 60px;
                line-height: 60px;
                background: #FD4446;
                border-radius: 6px;
                font-size: 33px;
            }
            .text {
                margin-top: 22px;
                font-size: 28px;
                line-height: 1;
                font-weight: 500;
            }
        }
        .left-menu {
            margin-top: 50px;
            background-color: transparent;
            border-right: 0;
            .el-menu-item {
                height: 60px;
                line-height: 60px;
                padding: 0!important;
                .nav-item {
                    height: 100%;
                    color: #fff;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    .iconfont {
                        color: #fff;
                        font-size: 20px;
                        margin: 0 20px 0 40px;
                    }
                }
                &:focus, &:hover {
                    background-color: #25354F;
                    .nav-item {
                        color: #fff;
                        .iconfont {
                            color: #fff;
                        }
                    }
                }
                &.is-active {
                    background: #25354F;
                    .nav-item {
                        color: #fff;
                        &:before {
                            content: '';
                            width: 2px;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: #FD4446;
                        }
                        .iconfont {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .main-box {
        width: 1%;
        flex: 1;
        background: #F7F9FB;
        height: 100%;
        display: flex;
        flex-direction: column;
        .header-box {
            height: 79px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-bottom: 1px solid #D1D2DB;
            position: relative;
            .time-out {
                .count-down {
                    color: #FD4446;
                }
            }
            .header-right {
                position: absolute;
                right: 0;
                display: flex;
                align-items: center;
                font-size: 16px;
                height: 80px;
                .user-info {
                    display: flex;
                    align-items: center;
                    .avatar {
                        width: 40px;
                        height: 40px;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-right: 10px;
                        img {
                            max-width: 100%;
                        }
                    }
                }
                .back-btn {
                    cursor: pointer;
                    transition: all .3s;
                    height: 100%;
                    line-height: 80px;
                    padding: 0 30px;
                    .iconfont {
                        margin-right: 10px;
                    }
                    &:hover {
                        color: #1122D8;
                    }
                }
            }
        }
        .content-box {
            height: 1%;
            flex: 1;
        }
    }
</style>