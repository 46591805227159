<template>
  <div class="judges-layout">
    <router-view />
  </div>
</template>


<script>
export default {
  name: "JudgesMain"
}
</script>

<style scoped lang="scss">
  .judges-layout {
    height: calc(100vh - 100px);
    padding-top: 15px;
  }
</style>