<template>
    <div class="view-container">
        <div class="header">
            <div class="login-box">
<!--                <div class="title">江苏省国家级电子商务师职业技能等级证书认定考核平台</div>-->
                <div class="title">电子商务师等级认定考试平台</div>
                <CountDown :timeObj="timeObj" />
               <div class="user-info" @click="goBackBtn">
                    <i class="iconfont logout-btn">&#xe60c;</i>
                    <span>退出</span>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="menu-box"  v-if="!noBar">
                <template v-if="levelOneMenu.length > 0">
                    <el-scrollbar class="levelOne-box">
                        <router-link :to="levelOne.path" class="levelOne"
                                     :class="{current: levelOne.name === $route.name || $route.meta.parentName && $route.meta.parentName.includes(levelOne.name)}"
                                     v-for="levelOne in levelOneMenu" :key="levelOne.name">
                            <span class="icon"></span>
                            <span class="one-text">{{levelOne.meta.title}}</span>
                        </router-link>
                    </el-scrollbar>
                </template>
            </div>
            <div class="main-container">
                <div class="content">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogout, examStuExamPage} from '@/utils/apis'
    import {mapState,mapMutations} from 'vuex'
    import CountDown from '../../components/student/CountDown.vue'

    export default {
        name: "studentExamLayout",
        data() {
            return {
                activeIndex: '/student/examing/goodsInfo',
                timeObj:{
                  begin_time:null,
                  end_time:null,
                },
                levelOneMenu:[],
            }
        },
        components: {
            CountDown
        },
        computed: {
          ...mapState({
            studentMenu: state => state.studentMenu[2].children,
          }),
          noBar() {
              console.log('route', this.$route);
              return this.$route.meta.nobar ? true : false
          }
        },
        mounted() {
            let active = localStorage.getItem('studentActive');
            let routeName = this.$route.name;
            if (routeName !== 'studentExamingGoodsInfo' && active !== undefined && active !== null) {
                this.activeIndex = active
            }
            this.getExamStuExamPage()
        },
        methods: {
          ...mapMutations(['setStudentExamData']),
          // 获取主页面信息
          getExamStuExamPage(){
            examStuExamPage().then((res) => {
                console.log(res,"获取主页面信息");
                this.setStudentExamData(res.data);
                this.timeObj.begin_time = res.data.begin_time;
                this.timeObj.end_time = res.data.end_time;
                this.exam_grade = res.data.exam_grade
                localStorage.setItem('exam_grade', res.data.exam_grade)
                let practicalObj = {}
                if (res.data.exam_grade === 1) { // 四级
                    practicalObj = {
                        studentExamingTheoryTest: res.data.theory_id ? res.data.theory_id : null,
                        studentExamingGoodsInfo: res.data.goods_info_collect ? res.data.goods_info_collect : null,
                        studentExamingOnlineShopDecoration: res.data.shop_decoration ? res.data.shop_decoration : null,
                        studentExamingOnlineShopManagement: res.data.shop_management ? res.data.shop_management : null,
                        studentExamingWebPromotion: res.data.network_promotion ? res.data.network_promotion : null,
                        studentExamingOnlineShopCustomerService: res.data.store_customer_service ? res.data.store_customer_service : null,
                        studentExamingECommerceDataCollectionAndCleaning: res.data.data_capture_clean ? res.data.data_capture_clean : null,
                    }
                } else if (res.data.exam_grade === 24) { // 三级
                    practicalObj = {
                        studentExamingTheoryTest: res.data.theory_id ? res.data.theory_id : null,
                        studentExamOnlineShopVisualDesign: res.data.store_visual_design ? res.data.store_visual_design : null,
                        studentExamInternetMarketing: res.data.network_marketing ? res.data.network_marketing : null,
                        studentExamOnlineTradeManage: res.data.online_transaction_management ? res.data.online_transaction_management : null,
                        studentExamInternetCustomerService: res.data.store_customer_service_level_three ? res.data.store_customer_service_level_three : null,
                        studentExamECommerceDataAnalysisAndApplication: res.data.data_analysis_application ? res.data.data_analysis_application : null,
                        studentExamProductSerive: res.data.product_service_management ? res.data.product_service_management : null,
                    }
                }else if(res.data.exam_grade === 74){
                  practicalObj = {
                    studentExamingTheoryTest: res.data.theory_id ? res.data.theory_id : null,
                    studentExamingOnlineShopManagement: res.data.shop_management ? res.data.shop_management : null,
                    studentExamInternetMarketing: res.data.network_marketing ? res.data.network_marketing : null,
                    studentExamingOnlineShopCustomerService: res.data.store_customer_service ? res.data.store_customer_service : null,
                    studentExamOnlineShopVisualDesign: res.data.store_visual_design ? res.data.store_visual_design : null,
                    studentExamECommerceDataAnalysisAndApplication: res.data.data_analysis_application ? res.data.data_analysis_application : null,
                    studentExamProductSerive: res.data.product_service_management ? res.data.product_service_management : null,
                  }
                } else if (res.data.exam_grade === 51) { // 四级跨境
                    practicalObj = {
                        studentExamOnlineShopVisualDesign: res.data.store_visual_design ? res.data.store_visual_design : null, // 线上店铺设计与装修
                        studentExamingWebPromotion: res.data.network_promotion ? res.data.network_promotion : null, // 营销推广
                        studentExamingOnlineShopManagement: res.data.shop_management ? res.data.shop_management : null, // 业务处理
                        studentExamingOnlineShopCustomerService: res.data.store_customer_service ? res.data.store_customer_service : null, // 客户服务
                        studentExamingECommerceDataCollectionAndCleaning: res.data.data_capture_clean ? res.data.data_capture_clean : null, // 商务数据分析
                        studentExamingGoodsInfo: res.data.goods_info_collect ? res.data.goods_info_collect : null, // 产品及服务信息管理
                    }
                }
                let allMenu = this.studentMenu.filter(item=>{
                    return !item.meta.hidden
                })
             
                this.levelOneMenu = allMenu.filter(item => practicalObj[item.name])
                 console.log("levelOneMenu",this.levelOneMenu)
            }).catch((err) => {})
          },
            //返回主界面
            goBackBtn() {
                // this.$router.push('/student/exam');
                userLogout().then((res) => {
                  localStorage.clear();
                  this.$router.push('/');
                  this.$message.success(res.msg);
                }).catch((err) => {
                  console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .view-container {
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            width: 100%;
            height: 60px;
            background: #fff;
            box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
            display: flex;
            z-index: 9;

            .login-box {
                width: 100%;
                font-size: 16px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                .title {
                    font-size: 18px;
                    font-weight: 400;
                    color: #333;
                }
                img {
                    height: 50px;
                    width: 94px;
                }
                .user-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    color: #2DC079;
                    font-size: 18px;
                    font-weight: 500;
                    cursor: pointer;
                    .logout-btn {
                        display: inline-block;
                        margin-right: 8px;
                        font-size: 18px;
                    }
                }
            }
        }

        .main {
            width: 100%;
            height: 1%;
            flex: 1;
            display: flex;
            .menu-box {
                width: 210px;
                height: 100%;
                margin-right: 20px;
                background-color: #fff;
            }
            .levelOne-box {
                position: relative;
                width: 100%;
                height: 100%;
                padding-top: 20px;
                display: flex;
                flex-direction: column;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .levelOne {
                    height: 60px;
                    padding: 0 10px 0 30px;
                    display: flex;
                    align-items: center;
                    color: #666;
                    line-height: 1;
                    transition: all .3s;
                    .iconfont {
                        font-size: 16px;
                    }
                    .one-text {
                        font-size: 14px;
                        font-weight: 500;
                        margin-left: 10px;
                    }
                    .icon {
                        display: inline-block;
                        width: 5px;
                        height: 60px;
                        background: transparent;
                        position: relative;
                        z-index: 999;
                        left: -30px;
                    }
                    &:hover {
                        color: #1AB163;
                    }
                    &.current {
                        color: #1AB163;
                        background: #E7F6EF;
                        .icon {
                            background: #1AB163;
                        }
                    }
                }
            }
            .main-container {
                display: flex;
                flex: 1;
                flex-direction: column;
                width: 1%;
                background: #fff;
                // margin-right: 20px;
                // margin-top: 20px;
                .levelTwo-wrapper {
                    height: 46px;
                    border-bottom: 2px solid #F1F5FF;
                    margin: 20px 20px 0 20px;
                    .levelTwo {
                        display: inline-block;
                        width: 163px;
                        height: 100%;
                        line-height: 46px;
                        border-radius: 4px 4px 0 0;
                        text-align: center;
                        color: #333333;
                        font-size: 16px;
                        &:hover {
                            color: #1AB163;
                        }
                        &.current {
                            color: #1AB163;
                            background: #E7F6EF;
                        }
                        &.single {
                            background: unset;
                            color: #333333;
                            text-align: left;
                        }
                    }
                }
                .content {
                    flex: 1;
                    height: 1%;
                    // padding-top: 10px;
                }
            }
        }
    }
</style>