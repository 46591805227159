<template>
    <div class="examData-wrapper">
        <div class="top-tab" v-if="showTap">
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <!-- <el-tab-pane label="理论题" name="examManageExamDataTheory" v-if="examTheory"></el-tab-pane> -->
                <template v-if="examPractice&&role==6&&type==1">
                    <el-tab-pane label="产品及服务信息管理" name="examManageExamDataGoodsInfo"></el-tab-pane>
                    <el-tab-pane label="线上店铺设计与装修" name="examManageExamDataStoreDecorate"></el-tab-pane>
                    <el-tab-pane label="营销推广" name="examManageExamDataNetworkPromote"></el-tab-pane>
                    <el-tab-pane label="业务处理" name="examManageExamDataStoreManage"></el-tab-pane>
                    <el-tab-pane label="客户服务" name="examManageExamDataStoreCustomer"></el-tab-pane>
                    <el-tab-pane label="商务数据分析" name="examManageExamDataECommerceData"></el-tab-pane>
                </template>
                
                <template v-else-if="examPractice&&role==4&&type==1">
                    <el-tab-pane label="产品及服务信息管理" name="judgesManageExamDataGoodsInfo"></el-tab-pane>
                    <el-tab-pane label="线上店铺设计与装修" name="judgesManageExamDataStoreDecorate"></el-tab-pane>
                    <el-tab-pane label="营销推广" name="judgesManageExamDataNetworkPromote"></el-tab-pane>
                    <el-tab-pane label="业务处理" name="judgesManageExamDataStoreManage"></el-tab-pane>
                    <el-tab-pane label="客户服务" name="judgesManageExamDataStoreCustomer"></el-tab-pane>
                    <el-tab-pane label="商务数据分析" name="judgesManageExamDataECommerceData"></el-tab-pane>
                </template>

                <template v-if="examPractice&&role==6&&type==2">
                    <el-tab-pane  label="产品及服务信息管理" name="examManageExamDataProductSeriveDetail"></el-tab-pane>
                    <el-tab-pane label="线上店铺设计与装修" name="examManageExamDataOnlineShopVisualDesignDetail"></el-tab-pane>
                    <el-tab-pane  label="营销推广" name="examManageExamDataCyberMarketing"></el-tab-pane>
                    <el-tab-pane  label="业务处理" name="examManageExamDataOnlineTradeManageDetail"></el-tab-pane>
                    <el-tab-pane v-if="modules.store_customer_service_level_three === null||modules.store_customer_service_level_three === ''||modules.store_customer_service_level_three === false" label="网络客户服务" name="TheerExamManageExamDataCyberCustomer"></el-tab-pane>
                    <el-tab-pane v-if="modules.store_customer_service_level_three === 42" label="客户管理服务" name="TheerExamManageExamDataCyberCustomer"></el-tab-pane>
                    <el-tab-pane v-if="modules.store_customer_service_level_three === 43" label="客户管理服务" name="examManageExamDataStoreCustomer"></el-tab-pane>
                    <el-tab-pane v-if="modules.store_customer_service_level_three === 35" label="客户服务" name="examManageExamDataStoreCustomer"></el-tab-pane>
                    <!-- <el-tab-pane label="数据分析应用" name="examManageExamDataECommerceAnalysisApplicationDetail"></el-tab-pane> -->
                    <el-tab-pane label="商务数据分析" name="examManageExamDataECommerceAnalysisApplicationDetail"></el-tab-pane>
                    
                </template>

                <template v-else-if="examPractice&&role==4&&type==2">
                    <el-tab-pane  label="产品及服务信息管理" name="judgesManageExamDataProductSeriveDetail"></el-tab-pane>
                    <el-tab-pane  label="线上店铺设计与装修" name="judgesManageExamDataOnlineShopVisualDesignDetail"></el-tab-pane>
                    <el-tab-pane  label="营销推广" name="judgesManageExamDataCyberMarketing"></el-tab-pane>
                    <el-tab-pane  label="业务处理" name="judgesManageExamDataOnlineTradeManageDetail"></el-tab-pane>
                    <el-tab-pane v-if="modules.store_customer_service_level_three === null||modules.store_customer_service_level_three === ''||modules.store_customer_service_level_three === false" label="网络客户服务" name="judgesManageExamDataCyberCustomer"></el-tab-pane>
                    <el-tab-pane v-if="modules.store_customer_service_level_three === 42" label="客户管理服务" name="judgesManageExamDataCyberCustomer"></el-tab-pane>
                    <el-tab-pane v-if="modules.store_customer_service_level_three === 43" label="客户管理服务" name="judgesManageExamDataStoreCustomer"></el-tab-pane>  
                    <el-tab-pane v-if="modules.store_customer_service_level_three === 35" label="客户服务" name="judgesManageExamDataStoreCustomer"></el-tab-pane>                 
                    <!-- <el-tab-pane label="数据分析应用" name="judgesManageExamDataECommerceAnalysisApplicationDetail"></el-tab-pane> -->
                    <el-tab-pane label="商务数据分析" name="judgesManageExamDataECommerceAnalysisApplicationDetail"></el-tab-pane>
                   
                </template>

              <template v-else-if="examPractice&&role==6&&type==5">
                <el-tab-pane  label="产品及服务信息管理" name="examManageExamDataProductSeriveDetail"></el-tab-pane>
                <el-tab-pane label="线上店铺设计与装修" name="examManageExamDataOnlineShopVisualDesignDetail"></el-tab-pane>
                <el-tab-pane  label="营销推广" name="examManageExamDataCyberMarketing"></el-tab-pane>
                <el-tab-pane label="业务处理" name="examManageExamDataStoreManage"></el-tab-pane>
                <el-tab-pane label="客户服务" name="examManageExamDataStoreCustomer"></el-tab-pane>
                <el-tab-pane label="商务数据分析" name="examManageExamDataECommerceAnalysisApplicationDetail"></el-tab-pane>
              </template>

              <template v-else-if="examPractice && role == 6 && type == 6">
                <el-tab-pane label="产品及服务信息管理" name="examManageExamDataGoodsInfo"></el-tab-pane>
                <el-tab-pane label="线上店铺设计与装修" name="examManageExamDataOnlineShopVisualDesignDetail"></el-tab-pane>
                <el-tab-pane label="营销推广" name="examManageExamDataNetworkPromote"></el-tab-pane>
                <el-tab-pane label="业务处理" name="examManageExamDataStoreManage"></el-tab-pane>
                <el-tab-pane label="客户服务" name="examManageExamDataStoreCustomer"></el-tab-pane>
                <el-tab-pane label="商务数据分析" name="examManageExamDataECommerceData"></el-tab-pane>
              </template>

              <template v-else-if="examPractice&&role==4&&type==5">
                <el-tab-pane  label="产品及服务信息管理" name="judgesManageExamDataProductSeriveDetail"></el-tab-pane>
                <el-tab-pane label="线上店铺设计与装修" name="judgesManageExamDataOnlineShopVisualDesignDetail"></el-tab-pane>
                <el-tab-pane  label="营销推广" name="judgesManageExamDataCyberMarketing"></el-tab-pane>
                <el-tab-pane label="业务处理" name="judgesManageExamDataStoreManage"></el-tab-pane>
                <el-tab-pane label="客户服务" name="judgesManageExamDataStoreCustomer"></el-tab-pane>
                <el-tab-pane label="商务数据分析" name="judgesManageExamDataECommerceAnalysisApplicationDetail"></el-tab-pane>
              </template>

              <template v-else-if="examPractice && role == 4 && type == 6">
                <el-tab-pane label="产品及服务信息管理" name="judgesManageExamDataProductSeriveDetail"></el-tab-pane>
                <el-tab-pane label="线上店铺设计与装修" name="judgesManageExamDataOnlineShopVisualDesignDetail"></el-tab-pane>
                <el-tab-pane label="营销推广" name="judgesManageExamDataCyberMarketing"></el-tab-pane>
                <el-tab-pane label="业务处理" name="judgesManageExamDataStoreManage"></el-tab-pane>
                <el-tab-pane label="客户服务" name="judgesManageExamDataStoreCustomer"></el-tab-pane>
                <el-tab-pane label="商务数据分析" name="judgesManageExamDataECommerceAnalysisApplicationDetail"></el-tab-pane>
              </template>

            </el-tabs>
            <div class="right" style="margin-top: 5px;">
                <el-button  type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="container">
            <router-view/>
        </div>
    </div>
</template>

<script>
import { examModules } from '@/utils/apis.js'
    export default {
        data() {
            return {
                examId: this.$route.query.examId || null,
                type: this.$route.query.type || null,
                studentId: this.$route.query.id || null,
                tabName: 'examManageExamDataTheory',
                role:localStorage.getItem('role'),
                modules: {}
            }
        },
        created() {
            if (this.$route.query.type) {
                this.type = parseInt(this.$route.query.type);
                localStorage.setItem('type', this.type);
            } else {
                this.type = parseInt(localStorage.getItem('type'));
            }
            this.tabName = this.$route.meta.tabName;
            this.getExamModules()
        },
        computed: {
            examPractice() {
                return [1, 2, 5, 6].includes(Number(this.type));
            },
            examTheory() {
                return [0,2].includes(Number(this.type));
            },
            showTap() {
                return this.$route.name != "judgesCenterExamDataECommerceCollectDetail";
            }
        },
        methods: {
            getExamModules() {
                if (Number(this.$route.query.type) === 0) {
                    return
                }
                examModules(this.$route.query.examId).then((res) => {
                    this.modules = res.data
                    console.log(this.modules,this.role ,'考试数据');
                })
            },
            toggleTab(tab) {
                let module = void 0;
                // 三级
                switch (this.role){
                    case '4':
                        if (tab.name === 'judgesManageExamDataOnlineShopVisualDesignDetail') {
                            module = this.modules.store_visual_design || void 0
                        }
                        if (tab.name === 'judgesManageExamDataCyberMarketing') {
                            module = this.modules.network_marketing || void 0
                        }
                        if (tab.name === 'judgesManageExamDataOnlineTradeManageDetail') {
                            module = this.modules.online_transaction_management || void 0
                        }
                        if (tab.name === 'judgesManageExamDataCyberCustomer') {
                            module = this.modules.store_customer_service_level_three || void 0
                        }
                        if (Number(this.type) === 2 && tab.name === 'judgesManageExamDataStoreCustomer') {
                            module = this.modules.store_customer_service_level_three || void 0
                        }
                        if (tab.name === 'judgesManageExamDataECommerceAnalysisApplicationDetail') {
                            module = this.modules.data_analysis_application || void 0
                        }
                        if (tab.name === 'judgesManageExamDataProductSeriveDetail') {
                            module = this.modules.product_service_management || void 0
                        }
                        if (tab.name === 'judgesManageExamDataStoreManage') {
                            module = this.modules.shop_management || void 0
                        }
                        break;
                    case '6':
                        if (tab.name === 'examManageExamDataOnlineShopVisualDesignDetail') {   // 线上店铺设计与装修
                            module = this.modules.store_visual_design || void 0
                        }
                        if (tab.name === 'examManageExamDataCyberMarketing') {
                            module = this.modules.network_marketing || void 0
                        }
                        if (tab.name === 'examManageExamDataOnlineTradeManageDetail') {
                            module = this.modules.online_transaction_management || void 0
                        }
                        if (tab.name === 'TheerExamManageExamDataCyberCustomer') {
                            module = this.modules.store_customer_service_level_three || void 0
                        }
                        if (tab.name === 'examManageExamDataStoreCustomer') {
                            module = this.modules.store_customer_service_level_three || void 0
                        }
                        if (tab.name === 'examManageExamDataECommerceAnalysisApplicationDetail') {
                            module = this.modules.data_analysis_application || void 0
                        }
                        if (Number(this.type) === 5  && tab.name === 'examManageExamDataProductSeriveDetail') {
                            module = this.modules.product_service_management || void 0
                        }
                        if (tab.name === 'examManageExamDataNetworkPromote') {   // 营销推广
                            module = this.modules.network_promotion || void 0
                        }
                        if (tab.name === 'examManageExamDataStoreManage') {   // 业务处理
                            module = this.modules.shop_management || void 0
                          
                        }
                        // if (tab.name === 'examManageExamDataStoreCustomer') {   // 客户服务
                        //     module = this.modules.store_customer_service || void 0
                        // }
                        if (tab.name === 'examManageExamDataECommerceData') {   // 商务数据分析
                            module = this.modules.data_capture_clean || void 0
                        }
                        if (Number(this.type) === 6  && tab.name === 'examManageExamDataGoodsInfo') {   // 产品及服务信息管理
                            module = this.modules.product_service_management || void 0
                        }
                        break;
                }
                console.log("moduleId",module);
                this.$router.push({
                    name: tab.name,
                    query: {
                        id: this.studentId,
                        examId: this.examId,
                        method: 'teaExam',
                        moduleId: module,
                        level:this.type
                    }
                })
            },
            goBack() {
                if(this.role==6){
                    this.$router.push({
                    path: '/examManage/examDataStudent',
                    query: {
                        examId: this.examId,
                        type: this.type
                    }
                })
                }else if(this.role==4){
                    this.$router.push({
                    path: '/judges/examstudata',
                    query: {
                        examId: this.examId,
                        type: this.type
                    }
                })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .examData-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }

    .top-tab {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #F1F5FF;
        }

        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }

    .tabs-full {
        width: 1%;
        flex: 1;

        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }

            .el-tabs__header {
                margin-bottom: 0;
            }

            .el-tabs__active-bar {
                display: none;
            }

            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;

                &.is-active {
                    background: #E7F6EF;
                }
            }

            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }

            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }
</style>