<template>
    <el-scrollbar class="main-wrapper">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="margin-left: 20px">
            <el-breadcrumb-item>数据中心</el-breadcrumb-item>
            <el-breadcrumb-item>{{stepActive === 1 ? '添加客户信息数据' :
                stepActive === 2 ? '添加行业大盘数据' :
                stepActive === 3 ? '添加商品榜单数据' :
                stepActive === 4 ? '添加客户分析数据' :
                stepActive === 5 ? '添加卖家分析数据' : '创建成功'}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="steps-wrapper steps-mini">
            <div class="step-item" :class="{current: [1, 2, 3, 4, 5, 6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">添加客户信息数据</div>
            </div>
            <div class="step-item" :class="{current: [2, 3, 4, 5, 6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">添加行业大盘数据</div>
            </div>
            <div class="step-item" :class="{current: [3, 4, 5, 6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">添加商品榜单数据</div>
            </div>
            <div class="step-item" :class="{current: [4, 5, 6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">4</div>
                <div class="step-title">添加客户分析数据</div>
            </div>
            <div class="step-item" :class="{current: [5, 6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">5</div>
                <div class="step-title">添加卖家分析数据</div>
            </div>
            <div class="step-item" :class="{current: [6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">6</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <router-view />
    </el-scrollbar>
</template>

<script>
    export default {
        data() {
            return {
                //步骤
                stepActive: Number(this.$route.query.stepActive) || 1,
                breadcrumbTitle: '',
            }
        },
        watch: {
            $route(val) {
                this.stepActive = Number(val.query.stepActive)
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
</style>