<template>
    <div class="view-container">
        <div class="header">
            <div class="login-box">
                <div class="title">电子商务师等级认定考试平台</div>
                <CountDown :timeObj="timeObj"/>
                <div class="user-info" @click="goBackBtn">
                    <i class="iconfont logout-btn">&#xe60c;</i>
<!--                    <span>返回主界面</span>-->
                    <span>退出</span>
                </div>
            </div>
        </div>
        <div class="main">
<!--            <div class="menu-box">
                <template v-if="levelOneMenu.length > 0">
                    <el-scrollbar class="levelOne-box">
                        <router-link :to="levelOne.path" class="levelOne"
                                     :class="{current: levelOne.name === $route.name || $route.meta.parentName && $route.meta.parentName.includes(levelOne.name)}"
                                     v-for="levelOne in levelOneMenu" :key="levelOne.name">
                            <span class="icon"></span>
                            <span class="one-text">{{levelOne.meta.title}}</span>
                        </router-link>
                    </el-scrollbar>
                </template>
            </div>-->
            <div class="main-container">
                <div class="content">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogout, getAuthen} from '@/utils/apis'
    import {mapState, mapMutations} from 'vuex'
    import CountDown from '../../components/student/CountDown.vue'

    export default {
        name: "studentExamLayout",
        data() {
            return {
                activeIndex: '/student/evaluation/examing/theoryTest/index',
                timeObj: {
                    begin_time: null,
                    end_time: null,
                },
                levelOneMenu: [],
            }
        },
        components: {
            CountDown
        },
        computed: mapState({
            studentMenu: state => state.studentMenu[state.studentMenu.length - 1 ].children,
        }),
        mounted() {
            let active = localStorage.getItem('studentActive');
            let routeName = this.$route.name;
            if (routeName !== 'studentExamingGoodsInfo' && active !== undefined && active !== null) {
                this.activeIndex = active
            }
            this.levelOneMenu = this.studentMenu.filter(item => {
                if(item.meta){
                    return !item.meta.hidden
                }
            })
            this.getExamStuExamPage()
        },
        methods: {
            ...mapMutations(['setEvaluationStudentExamData']),
            // 获取主页面信息
            getExamStuExamPage() {
                let params = {
                    id: this.$route.query.id
                }
                getAuthen(params).then((res) => {
                    this.setEvaluationStudentExamData(res.data);
                    this.timeObj.begin_time = res.data.start_time;
                    this.timeObj.end_time = res.data.end_time;
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            //返回主界面
            /*goBackBtn() {
                this.$router.push({
                    path: '/authen/authenticate',
                    query: {
                        id: this.$route.query.id
                    }
                });
            }*/
            goBackBtn() {
              // this.$router.push('/student/exam');
              userLogout().then((res) => {
                localStorage.clear();
                this.$router.push('/');
                this.$message.success(res.msg);
              }).catch((err) => {
                console.log(err);
              })
            }
        }
    }
</script>

<style scoped lang="scss">
    .view-container {
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            width: 100%;
            height: 60px;
            background: #fff;
            box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
            display: flex;
            z-index: 9;

            .login-box {
                width: 100%;
                font-size: 16px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;

                .title {
                    font-size: 18px;
                    font-weight: 400;
                    color: #333;
                }

                img {
                    height: 50px;
                    width: 94px;
                }

                .user-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    color: #2DC079;
                    font-size: 18px;
                    font-weight: 500;
                    cursor: pointer;

                    .logout-btn {
                        display: inline-block;
                        margin-right: 8px;
                        font-size: 18px;
                    }
                }
            }
        }

        .main {
            width: 100%;
            height: 1%;
            flex: 1;
            display: flex;

            .menu-box {
                width: 210px;
                height: 100%;
                margin-right: 20px;
                background-color: #fff;
            }

            .levelOne-box {
                position: relative;
                width: 100%;
                height: 100%;
                padding-top: 20px;
                display: flex;
                flex-direction: column;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }

                .levelOne {
                    height: 60px;
                    padding: 0 10px 0 30px;
                    display: flex;
                    align-items: center;
                    color: #666;
                    line-height: 1;
                    transition: all .3s;

                    .iconfont {
                        font-size: 16px;
                    }

                    .one-text {
                        font-size: 14px;
                        font-weight: 500;
                        margin-left: 10px;
                    }

                    .icon {
                        display: inline-block;
                        width: 5px;
                        height: 60px;
                        background: transparent;
                        position: relative;
                        z-index: 999;
                        left: -30px;
                    }

                    &:hover {
                        color: #1AB163;
                    }

                    &.current {
                        color: #1AB163;
                        background: #E7F6EF;

                        .icon {
                            background: #1AB163;
                        }
                    }
                }
            }

            .main-container {
                display: flex;
                flex: 1;
                flex-direction: column;
                width: 1%;
                background: #fff;
                margin-right: 20px;

                .levelTwo-wrapper {
                    height: 46px;
                    border-bottom: 2px solid #F1F5FF;
                    margin: 20px 20px 0 20px;

                    .levelTwo {
                        display: inline-block;
                        width: 163px;
                        height: 100%;
                        line-height: 46px;
                        border-radius: 4px 4px 0 0;
                        text-align: center;
                        color: #333333;
                        font-size: 16px;

                        &:hover {
                            color: #1AB163;
                        }

                        &.current {
                            color: #1AB163;
                            background: #E7F6EF;
                        }

                        &.single {
                            background: unset;
                            color: #333333;
                            text-align: left;
                        }
                    }
                }

                .content {
                    flex: 1;
                    height: 1%;
                    padding-top: 20px;
                }
            }
        }
    }
</style>