import axios from 'axios';
import router from "../router";
import {Message} from 'element-ui';
import { Loading } from 'element-ui';

export const instance = axios.create({
    // baseURL: 'http://app.e-class.me/',
    // timeout: 30000,
    headers: {
        'Cache-Control': 'no-cache',
    },
});
instance.defaults.headers.post['Content-Type'] = 'application/json';

const rolesToken = {
    1: 'adminToken',
    2: 'schoolToken',
    3: 'teacherToken',
    4: 'judgesToken',
    5: 'studentToken',
    6: 'evaluationToken'
}

let loadingInstance = null;

instance.interceptors.request.use(
    (config) => {

        loadingInstance =  Loading.service({
            lock: true,
            text: 'loading',
            spinner: 'el-icon-loading',
        });
        let token = null;
        if (router.currentRoute.name === 'login') {
            token = localStorage.getItem(rolesToken[5])
        } else {
            token = localStorage.getItem(rolesToken[router.currentRoute.meta.role])
        }

        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
// 响应拦截
instance.interceptors.response.use(
    (response) => {
        if (response.data.code === 200 || response.data.code === 208) {
            loadingInstance.close();
            return response.data;
        }
        if (response.data.code === 204) {
            loadingInstance.close();
            Message.warning(response.data.msg);
            localStorage.clear();
            if (router.currentRoute.fullPath !== '/') {
                router.push('/');
            }
        } else {
            loadingInstance.close();
            if (!response.config.headers.hideMessage) {
                Message.warning(response.data.msg)
            }
            return response.data;
        }
    },
    (error) => {
        loadingInstance.close();
        Message.error(String(error))
        return Promise.reject(error);
    },
);
export default {
    get(url, config = {}) {
        return instance.get(url, config);
    },
    post(url, data = {}, config = {}) {
        return instance.post(url, data, config);
    },
    delete(url, config = {}) {
        return instance.delete(url, config);
    },
    patch(url, data, config = {}) {
        return instance.patch(url, data, config);
    },
    put(url, data, config = {}) {
        return instance.put(url, data, config);
    },
    serviceIns(url, formData, config)  {
        if (localStorage.getItem('token')) {
            // config.headers['authorization'] = localStorage.getItem('token')
            config = {...config, headers: {authorization: localStorage.getItem('token')}}
        }
        return axios.create({baseURL: baseUrl}).post(url, formData, config)
    }
};
