<template>
    <div class="view-container">
        <div class="header">
            <div class="login-box">
                <div class="title">{{ checkerName }}</div>
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="userAvatar ? userAvatar : require('../../assets/images/user_img.png')" alt="">
                    </div>
                    <div class="user-name">{{userName}}</div>
                    <i @click="logoutBtn" class="iconfont logout-btn">&#xe60c;</i>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="menu-box" v-if="hasMenu">
                <template v-if="judgesMenu.length > 0">
                    <el-scrollbar class="levelOne-box">
                        <router-link :to="levelOne.path" class="levelOne"
                                     :class="{current: levelOne.name === $route.name || $route.meta.parentName && $route.meta.parentName.includes(levelOne.name)}"
                                     v-for="levelOne in levelOneMenu" :key="levelOne.name">
                            <i class="iconfont" v-html="levelOne.meta.icon"></i>
                            <span class="icon"></span>
                            <span class="one-text">{{levelOne.meta.title}}</span>
                        </router-link>
                    </el-scrollbar>
                </template>
            </div>
            <div class="main-container">
                <!--<div class="levelTwo-wrapper"  v-if="levelTwoMenu.length > 0 && showTab">-->
                    <!--<router-link :to="levelTwo.path" class="levelTwo"-->
                                 <!--:class="{current: levelTwo.name === $route.name || $route.meta.parentName.includes(levelTwo.name), single:levelTwoMenu.length === 1}"-->
                                 <!--v-for="levelTwo in levelTwoMenu" :key="levelTwo.name">-->
                        <!--<span class="two-text">{{levelTwo.meta.title}}</span>-->
                    <!--</router-link>-->
                <!--</div>-->
                <div class="content">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogout} from '@/utils/apis'
    import {mapState} from 'vuex'
    export default {
        name: "JudgesLayout",
        data() {
            return {
                activeIndex: '/judges/theoreticalQuestions',
                userName: '',
                userAvatar: '',
                role: '',
                levelOneMenu: [],
                levelTwoMenu: [],
                showTab:true,
                hasMenu:true,
                checkerName: localStorage.getItem('checkerName') ? localStorage.getItem('checkerName') : '鉴定中心-评委端',
            }
        },
        computed: mapState({
            judgesMenu: state => state.judgesMenu[0].children,
        }),
        watch: {
            $route(route) {
                this.getLevelTwoMenu(route);
              
                if(route.name == 'judgesWebPromotionIndex'){
                    this.hasMenu = true
                }
                if(route.name == 'judgesCyberCustomerDetail'){
                    this.hasMenu = false
                }
                if(route.name == 'judgesCyberCustomerIndex'){
                    this.hasMenu = true
                }
               
            }
        },
        mounted() {
            this.levelOneMenu = this.judgesMenu.filter(item=>{
                return !item.meta.hidden
            })
            this.role = localStorage.getItem('role');
            if (this.role === '4') {
                this.userName = localStorage.getItem('judgesName');
                this.userAvatar = localStorage.getItem('judgesAvatar');
            }

            let active = localStorage.getItem('judgesActive');
            let routeName = this.$route.name;
       
            if(routeName == 'judgesCyberCustomerDetail'){
                this.hasMenu = false
            }
         
            if (routeName !== 'judgesTheoreticalQuestionsIndex' && active !== undefined && active !== null) {
                this.activeIndex = active
            }
            this.getLevelTwoMenu(this.$route)
        },
        methods: {
            // 获取二级菜单
            getLevelTwoMenu(route) {
                if(route.meta.level && route.meta.level === 2){
                    this.showTab = false
                } else {
                    this.showTab = true
                }
                const twoArr = this.judgesMenu.find(item => {
                    if (route.meta.parentName) {
                        return route.meta.parentName[0] === item.name
                    } else {
                        return item.name === route.name
                    }
                })
                if (twoArr.children) {
                    this.levelTwoMenu = twoArr.children.filter(item => !item.meta.hidden)
                } else {
                    this.levelTwoMenu = []
                }
            },
            // 退出
            logoutBtn() {
                userLogout().then((res) => {
                    localStorage.clear();
                    this.$router.push('/');
                    this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .view-container {
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            width: 100%;
            height: 60px;
            background: #fff;
            box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
            display: flex;
            z-index: 9;

            .login-box {
                width: 100%;
                font-size: 16px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                .title {
                    font-size: 18px;
                    font-weight: 400;
                    color: #333;
                }
                .user-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .user-avatar {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 50%;
                        }
                    }

                    .user-name {
                        width: 100px;
                        max-height: 50px;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #333;
                    }

                    .logout-btn {
                        cursor: pointer;
                        margin-left: 20px;
                        color: #2DC079;
                    }
                }
            }
        }

        .main {
            width: 100%;
            height: 1%;
            flex: 1;
            display: flex;
            .menu-box {
                width: 210px;
                height: 100%;
                margin-right: 20px;
                background-color: #fff;
            }
            .levelOne-box {
                position: relative;
                width: 100%;
                height: 100%;
                padding-top: 20px;
                display: flex;
                flex-direction: column;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .levelOne {
                    height: 60px;
                    padding: 0 10px 0 30px;
                    display: flex;
                    align-items: center;
                    color: #666;
                    line-height: 1;
                    transition: all .3s;
                    .iconfont {
                        font-size: 16px;
                    }
                    .one-text {
                        font-size: 16px;
                        font-weight: 500;
                        margin-left: 10px;
                    }
                    .icon {
                        display: inline-block;
                        width: 5px;
                        height: 60px;
                        background: transparent;
                        position: relative;
                        z-index: 999;
                        left: -30px;
                    }
                    &:hover {
                        color: #1AB163;
                    }
                    &.current {
                        color: #1AB163;
                        background: #E7F6EF;
                        .icon {
                            background: #1AB163;
                        }
                    }
                }
            }
            .main-container {
                display: flex;
                flex: 1;
                flex-direction: column;
                width: 1%;
                /*background: #fff;*/
                /*margin-right: 20px;*/
                .levelTwo-wrapper {
                    height: 46px;
                    border-bottom: 2px solid #F1F5FF;
                    margin: 20px 20px 0 20px;
                    .levelTwo {
                        display: inline-block;
                        width: 146px;
                        height: 100%;
                        line-height: 46px;
                        border-radius: 4px 4px 0 0;
                        text-align: center;
                        color: #333333;
                        font-size: 16px;
                        &:hover {
                            color: #1AB163;
                        }
                        &.current {
                            color: #1AB163;
                            background: #E7F6EF;
                        }
                        &.single {
                            background: unset;
                            color: #333333;
                            text-align: left;
                        }
                    }
                }
                .content {
                    flex: 1;
                    height: 1%;
                    margin: 20px 20px 20px 0;
                    background-color: #fff;
                }
            }
        }
    }
</style>