<template>
    <div class="view-container">
        <div class="header">
            <div class="login-box">
                <div class="title">电子商务师等级认定考试平台</div>
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="userAvatar ? userAvatar : require('../../assets/images/user_img.png')" alt="">
                    </div>
                    <div class="user-name">{{userName}}</div>
                    <i @click="logoutBtn" class="iconfont logout-btn">&#xe60c;</i>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="menu-box" v-if="hasMenu">
                <el-scrollbar class="levelOne-box">
                    <div class="levelOne"
                         :class="{current: levelOne.name === $route.name || $route.meta.parentName && $route.meta.parentName.includes(levelOne.name)}"
                         v-for="levelOne in studentMenu" :key="levelOne.name"
                         @click="clickTab(levelOne)">
                        <i class="iconfont" v-html="levelOne.meta.icon"></i>
                        <span class="one-text">{{levelOne.meta.title}}</span>
                        <div v-if="levelOne.name !== 'studentCourse'">
                            <i class="iconfont has-tow-level" v-if="clickLevelIcon">&#xe601;</i>
                            <i class="iconfont has-tow-level" v-if="!clickLevelIcon">&#xe602;</i>
                        </div>
                        <span class="icon"></span>
                    </div>
                    <router-link  v-if="clickLevelIcon" :to="levelTwo.path" class="levelTwo"
                                  :class="{current: levelTwo.name === $route.name || $route.meta.parentName.includes(levelTwo.name), single:levelTwoMenu.length === 1}"
                                  v-for="levelTwo in levelTwoMenu" :key="levelTwo.name">
                        <span class="two-text">{{levelTwo.meta.title}}</span>
                    </router-link>
                </el-scrollbar>
            </div>
            <div class="main-container">
                <div class="content">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogout} from '@/utils/apis'
    import {mapState} from 'vuex'
    export default {
        name: "StudentLayout",
        data() {
            return {
                activeIndex: '/course',
                userName: '',
                userAvatar: '',
                role: '',
                levelTwoMenu: [],
                showTab:true,
                //是否点击一级菜单
                clickLevelIcon: false,
                hasMenu: true,
            }
        },
        computed: mapState({
            studentMenu: state => state.studentMenu[0].children,
        }),
        watch: {
            $route(route) {
                this.getLevelTwoMenu(route)
                if(route.name == 'studentArticleDetail') {
                    this.hasMenu = false
                }
                if(route.name == 'studentTrainCenterNetworkDetail') {
                    this.hasMenu = true
                }
            }
        },
        mounted() {
            this.role = localStorage.getItem('role');
            if (this.role === '5') {
                this.userName = localStorage.getItem('studentName');
                this.userAvatar = localStorage.getItem('studentAvatar');
            }

            let active = localStorage.getItem('studentActive');
            let routeName = this.$route.name;
            if (routeName !== 'studentCourseIndex' && active !== undefined && active !== null) {
                this.activeIndex = active
            }
            this.getLevelTwoMenu(this.$route)
            if (routeName === 'studentCourseIndex' || routeName === 'studentFeatured') {
                this.clickLevelIcon = false;
            } else {
                this.clickLevelIcon = true;
            }
            if(routeName == 'studentArticleDetail') {
                this.hasMenu = false
            }
        },
        methods: {
            // 获取二级菜单
            getLevelTwoMenu(route) {
                if(route.meta.level && route.meta.level === 2){
                    this.showTab = false
                } else {
                    this.showTab = true
                }
                const twoArr = this.studentMenu.find(item => {
                    if (route.meta.parentName) {
                        return route.meta.parentName[0] === item.name
                    } else {
                        return item.name === route.name
                    }
                })
                if (twoArr.children) {
                    this.levelTwoMenu = twoArr.children.filter(item => !item.meta.hidden)
                } else {
                    this.levelTwoMenu = []
                }
            },
            //点击一级菜单
            clickTab(item) {
                if (item.name === 'studentCourse') {
                    this.$router.push('/student/course');
                    this.clickLevelIcon = false;
                } else if (item.name === 'studentTrainCenter') {
                    this.$router.push('/student/trainCenter/index');
                    this.clickLevelIcon = !this.clickLevelIcon;
                }
            },
            // 退出
            logoutBtn() {
                userLogout().then((res) => {
                    localStorage.clear();
                    this.$router.push('/');
                    this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .view-container {
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            width: 100%;
            height: 60px;
            background: #fff;
            box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
            display: flex;
            z-index: 9;

            .login-box {
                width: 100%;
                font-size: 16px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                .title {
                    display: flex;
                    font-size: 20px;
                    font-weight: 400;
                    color: #333;
                }
                .user-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .user-avatar {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 50%;
                        }
                    }

                    .user-name {
                        width: 100px;
                        max-height: 50px;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #333;
                    }

                    .logout-btn {
                        cursor: pointer;
                        margin-left: 20px;
                        color: #333;
                    }
                }
            }
        }

        .main {
            width: 100%;
            height: 1%;
            flex: 1;
            display: flex;
            .menu-box {
                width: 210px;
                height: 100%;
                margin-right: 20px;
                background-color: #fff;
            }
            .levelOne-box {
                position: relative;
                width: 100%;
                height: 100%;
                padding-top: 20px;
                display: flex;
                flex-direction: column;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .levelOne {
                    height: 60px;
                    padding: 0 10px 0 30px;
                    display: flex;
                    align-items: center;
                    color: #666;
                    line-height: 1;
                    transition: all .3s;
                    cursor: pointer;
                    .iconfont {
                        font-size: 16px;
                    }
                    .one-text {
                        display: inline-block;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 500;
                        margin-left: 10px;
                    }
                    .icon {
                        display: inline-block;
                        width: 5px;
                        height: 60px;
                        background: transparent;
                        position: relative;
                        z-index: 999;
                        left: -196px;
                    }
                    &:hover {
                        color: #1AB163;
                    }
                    &.current {
                        color: #1AB163;
                        background: #E7F6EF;
                        .icon {
                            background: #1AB163;
                        }
                    }
                }
            }
            .main-container {
                display: flex;
                flex: 1;
                flex-direction: column;
                width: 1%;
                background: #fff;
                margin-top: 20px;
                margin-right: 20px;
                box-sizing: border-box;
                .levelTwo-wrapper {
                    height: 46px;
                    border-bottom: 2px solid #F1F5FF;
                    margin: 20px 20px 0 20px;
                }
                .content {
                    flex: 1;
                    height: 1%;
                    padding-top: 20px;
                }
            }
        }
    }
    .levelTwo {
        display: inline-block;
        width: 156px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px 4px 0 0;
        margin-left: 54px;
        color: #333333;
        font-size: 16px;
        &:hover {
            color: #1AB163;
        }
        &.current {
            color: #1AB163;
        }
        &.single {
            background: unset;
            color: #333333;
            text-align: left;
        }
    }
</style>