/*
* 公共方法
* */
import {Message} from "element-ui"
import _ from "underscore"
export const randomStr = (len=16) => {
	let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
	let maxPos = $chars.length;
	let string = '';
	for (let i = 0; i < len; i++) {
		string += $chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return string;
}

export const chineseNumber = (index) => {
	let str = '一二三四五六七八九十';
	return str[index];
}

export const sliceFile = (file, blockSize = 10 * 1024 * 1024) => {
	let blobList = [];
	const total = Math.ceil(file.size / blockSize);
	for (let i = 0; i < total; i++) {
		const start = i * blockSize;
		const end = start + blockSize;
		const blob = file.slice(start, end);
		blobList.push(blob);
	}
	return [blobList, total];
}
export const fileNameExt = (fileName) => {
	const fileInfo = fileName.split('.');
	if (fileInfo.length > 2) {
		let fileExt = fileInfo[fileInfo.length - 1].toLowerCase();
		let fileName = fileInfo.splice(0, fileInfo.length - 1).join('.');
		return [fileName, fileExt];
	} else {
		return [fileInfo[0], fileInfo[1].toLowerCase()];
	}
}

export const errMsg = (err) => {
	console.log("err", err)
	Message.error(err.message)
}

export const isCompetion = () => {
	return Number(localStorage.getItem("exam_type")) === 1;
}
export const toFormat2dot = (val) => {
	return Number(val.toFixed(2))
}
export const sortByIndex = (rank, price) => {
	let bid_position = 0;
	_.each(rank, (item, index) => {
		if (price >= parseFloat(item) && index === 0) {
			bid_position = 1;
		} else if (
			bid_position === 0 && price <= parseFloat(item) &&
			price >= parseFloat(rank[index + 1]) &&
			index < rank.length
		) {
			bid_position = index + 2;
		} else if (bid_position === 0 && index === rank.length - 1) {
			bid_position = rank.length + 1;
		}
	});
	return bid_position;
}

//将数组的元素转为整型，并且倒叙
export  const numberReverse = (arr) => {
	let tmp_arr = [];
	tmp_arr = _.map(arr, (item) => {
		return Number(item);
	});
	tmp_arr = _.sortBy(tmp_arr, (item) => {
		return -item
	});
	return tmp_arr;
}
