<template>
    <div class="view-container">
        <div class="header">
            <div class="login-box">
                <div class="title">电子商务师等级认定考试平台</div>
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="userAvatar ? userAvatar : require('../../assets/images/user_img.png')" alt="">
                    </div>
                    <div class="user-name">{{userName}}</div>
                    <i @click="logoutBtn" class="iconfont logout-btn">&#xe60c;</i>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="menu-box" v-if="hasMenu">
                <template>
                    <el-scrollbar class="levelOne-box">
                        <div class="levelOne"
                             :class="{current: levelOne.name === $route.name || $route.meta.parentName && $route.meta.parentName.includes(levelOne.name)}"
                             v-for="(levelOne, oneIndex) in levelOneMenu" :key="levelOne.name">
                            <div class="level-one" @click="clickTab(levelOne, oneIndex)">
                                <i class="iconfont" v-html="levelOne.meta.icon" style="margin-left: 30px;"></i>
                                <span class="one-text">{{levelOne.meta.title}}</span>
                                <div v-if="levelOne.name !== 'dataCenter' && levelOne.name !== 'teachingCenter'" style="margin-right: 10px;">
                                    <i class="iconfont has-tow-level" v-if="levelIndex" :class="{selected : levelIndex === oneIndex}" v-html="levelIndex === oneIndex ? '&#xe601' : '&#xe602'">;</i>
                                    <i class="iconfont close" :class="{noSelected : levelIndex === oneIndex}">&#xe602;</i>
                                </div>
                                <span class="icon"></span>
                            </div>
                            <div v-if="levelOne.name !== 'dataCenter' && levelOne.name !== 'teachingCenter'" :class="{selected : levelIndex === oneIndex}" class="has-tow-level level-two">
                                <router-link v-if="!levelTwo.meta.hidden" :to="levelTwo.path" class="levelTwo"
                                             :class="{current: levelTwo.meta.isActiveName === $route.meta.isActiveName}"
                                             v-for="levelTwo in levelOne.children" :key="levelTwo.name">
                                    <span class="two-text">{{levelTwo.meta.title}}</span>
                                </router-link>
                            </div>

                        </div>
                    </el-scrollbar>
                </template>
            </div>
            <div class="main-container">
                <div class="content">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogout} from '@/utils/apis'
    import {mapState} from 'vuex'

    export default {
        name: "TeacherLayout",
        data() {
            return {
                activeIndex: '/teachingCenter',
                userName: '',
                userAvatar: '',
                role: '',
                levelOneMenu: [],
                levelTwoMenu: [],
                showTab:true,
                showRoute:true,
                leftRouteIcon:[
                    {
                        title:'教学中心',
                        icon:'&#xe65a;'
                    },
                    {
                        title:'训练中心',
                        icon:'&#xe615;'
                    },
                    {
                        title:'考试中心',
                        icon:'&#xe62f;'
                    },
                    {
                        title:'评价中心',
                        icon:'&#xe663;'
                    },
                    {
                        title:'数据中心',
                        icon:'&#xe6e1;'
                    },
                    {
                        title:'教务中心',
                        icon:'&#xe66d;'
                    },
                ],
                //是否点击一级菜单
                levelIndex: 0,
                //有左侧菜单
                hasMenu: true
            }
        },
        computed: mapState({
            teacherMenu: state => state.teacherMenu[0].children,
        }),
        watch: {
            $route(route) {
                this.getLevelTwoMenu(route)
                if (route.name === 'evaluationCenterGetCertificateList') {
                    this.hasMenu = true;
                }
                if (route.name === 'evaluationCenterGenerateCertificate') {
                    this.hasMenu = false;
                }
                if (route.name === 'evaluationCenterNoGetCertificateList') {
                    this.hasMenu = true;
                }
                if (route.name === 'evaluationCenterCertificateManagementModel') {
                    this.hasMenu = true;
                }
                if (route.name === 'evaluationCenterViewCertificate') {
                    this.hasMenu = false;
                }
                if (route.name === 'evaluationCenterCertificateModel') {
                    this.hasMenu = false;
                }
                if (route.name === 'ReportManagementSingleReport') {
                    this.hasMenu = false;
                }
                if (route.name === 'ReportManagementDetail') {
                    this.hasMenu = true;
                }
                if (route.name === 'teacherArticleDetail') {
                    this.hasMenu = false;
                }
                if (route.name === 'teacherCyberCustomerDetail') {
                    this.hasMenu = false;
                }
                if (route.name === 'evaluationCenterWebPromotionIndex') {
                    this.hasMenu = true;
                }
                if (route.name === 'examCenterTrainingDataNetworkPromote') {
                    this.hasMenu = true;
                }
                if (route.name === 'examCenterExamDataNetworkDetail') {
                    this.hasMenu = true;
                }
                if (route.name === 'examCenterExamDataNetworkPromote') {
                    this.hasMenu = true;
                }
                if (route.name === 'strainingCenterPracticalTrainingCyberCustomerServiceManagementDetail') {
                    this.hasMenu = false;
                }
                if (route.name === 'strainingCenterPracticalTrainingCyberCustomerDetail') {
                    this.hasMenu = true;
                }
                if (route.name === 'examCenterTrainingDataCyberCustomer') {
                    this.hasMenu = true;
                }
                if (route.name === 'evaluationCenterCyberCustomerIndex') {
                    this.hasMenu = true;
                }
                if (route.name === 'examCenterExamDataCyberCustomer') {
                    this.hasMenu = true;
                }
            }
        },
        mounted() {
            this.levelOneMenu = this.teacherMenu.filter(item=>{
                return !item.meta.hidden
            })
            this.role = localStorage.getItem('role');
            if (this.role === '3') {
                this.userName = localStorage.getItem('teacherName');
                this.userAvatar = localStorage.getItem('teacherAvatar');
            }

            let active = localStorage.getItem('teacherActive');
            let routeName = this.$route.name;
            if (routeName !== 'teachingCenterGoodsInfo' && active !== undefined && active !== null) {
                this.activeIndex = active
            }
            this.getLevelTwoMenu(this.$route)
            let activeName = this.$route.meta.isActiveName;
            if (activeName === 'trainingCenterExamTraining' || activeName === 'trainingCenterPracticalTraining') {
                this.levelIndex = 1;
            }
            if (activeName === 'examCenterExamManagement' || activeName === 'examCenterExamData' || activeName === 'examCenterTrainingData') {
                this.levelIndex = 2;
            }
            if (activeName === 'evaluationCenterScoreManagement' || activeName === 'evaluationCenterCertificateManagement' || activeName === 'evaluationCenterReportManagement') {
                this.levelIndex = 3;
            }
            if (activeName === 'educationCenterClassManagement' || activeName === 'educationCenterStudentManagement') {
                this.levelIndex = 5;
            }
            if (routeName === 'evaluationCenterViewCertificate') {
                this.hasMenu = false;
            }
            if (routeName === 'evaluationCenterCertificateModel') {
                this.hasMenu = false;
            }
            if (routeName === 'evaluationCenterGenerateCertificate') {
                this.hasMenu = false;
            }
            if (routeName === 'ReportManagementSingleReport') {
                this.hasMenu = false;
            }
            if (routeName === 'teacherArticleDetail') {
                this.hasMenu = false;
            }
            if (routeName === 'strainingCenterPracticalTrainingCyberCustomerServiceManagementDetail') {
                this.hasMenu = false;
            }
            if (routeName === 'teacherCyberCustomerDetail') {
                this.hasMenu = false;
            }
        },
        methods: {
            // 获取二级菜单
            getLevelTwoMenu(route) {
                if(route.meta.level && route.meta.level === 2){
                    this.showTab = false
                } else {
                    this.showTab = true
                }
                if(route.meta.showRoute !== undefined){
                    this.showRoute = false
                } else {
                    this.showRoute = true
                }
                const twoArr = this.teacherMenu.find(item => {
                    if (route.meta.parentName) {
                        return route.meta.parentName[0] === item.name
                    } else {
                        return item.name === route.name
                    }
                })
                if (twoArr.children) {
                    this.levelTwoMenu = twoArr.children.filter(item => !item.meta.hidden)
                } else {
                    this.levelTwoMenu = []
                }
            },
            //点击一级菜单
            clickTab(item, index) {
                if (item.name === 'teachingCenter') {
                    this.$router.push('/teachingCenter/index');
                    this.levelIndex = false;
                } else if (item.name === 'dataCenter') {
                    this.$router.push('/dataCenter');
                    this.levelIndex = false;
                } else {
                    this.levelIndex = index;
                    this.$router.push(item.path);
                }
            },
            // 退出
            logoutBtn() {
                userLogout().then((res) => {
                    localStorage.clear();
                    this.$router.push('/');
                    this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .view-container {
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            width: 100%;
            height: 60px;
            background: #fff;
            box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
            display: flex;
            z-index: 9;

            .login-box {
                width: 100%;
                font-size: 16px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                .title {
                    display: flex;
                    font-size: 20px;
                    font-weight: 400;
                    color: #333;
                }
                .user-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .user-avatar {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 50%;
                        }
                    }

                    .user-name {
                        width: 100px;
                        max-height: 50px;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #333;
                    }

                    .logout-btn {
                        cursor: pointer;
                        margin-left: 20px;
                        color: #aaa;
                    }
                }
            }
        }

        .main {
            width: 100%;
            height: 1%;
            flex: 1;
            display: flex;
            overflow: hidden;
            .menu-box {
                width: 210px;
                height: 100%;
                background-color: #fff;
                overflow: hidden;
            }
            .levelOne-box {
                width: 100%;
                height: 100%;
                padding-top: 20px;
                display: flex;
                flex-direction: column;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .levelOne {
                    /*height: 60px;*/
                    /*padding: 0 10px 0 30px;*/
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #666;
                    line-height: 1;
                    transition: all .3s;
                    cursor: pointer;
                    .level-one {
                        display: flex;
                        align-items: center;
                        width: 100%;
                    }
                    .iconfont {
                        font-size: 16px;
                    }
                    .one-text {
                        display: inline-block;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 500;
                        margin-left: 10px;
                    }
                    .icon {
                        display: inline-block;
                        width: 5px;
                        height: 60px;
                        background: transparent;
                        position: relative;
                        z-index: 999;
                        left: -206px;
                    }
                    &:hover {
                        color: #2DC079;
                    }
                    &.current {
                        color: #2DC079;
                        background: #F6F8F9;
                        .icon {
                            background: #2DC079;
                        }
                        .level-two {
                            background-color: #fff;
                        }
                    }
                }
            }
            .main-container {
                display: flex;
                flex: 1;
                flex-direction: column;
                width: 1%;
                background: #fff;
                margin: 20px;
                overflow: hidden;
                .levelTwo-wrapper {
                    height: 46px;
                    border-bottom: 2px solid #F1F5FF;
                    margin: 20px 20px 0 20px;
                    .levelTwo {
                        display: inline-block;
                        width: 106px;
                        height: 100%;
                        line-height: 46px;
                        border-radius: 4px 4px 0 0;
                        text-align: center;
                        color: #333333;
                        font-size: 16px;
                        &:hover {
                            color: #2DC079;
                        }
                        &.current {
                            color: #2DC079;
                            background: #E7F6EF;
                        }
                        &.single {
                            background: unset;
                            color: #333333;
                            text-align: left;
                        }
                    }
                }
                .content {
                    flex: 1;
                    height: 1%;
                    padding-top: 20px;
                    position: relative;
                }
            }
        }
    }
    .levelTwo {
        display: inline-block;
        width: 156px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px 4px 0 0;
        margin-left: 54px;
        color: #333333;
        font-size: 16px;
        &:hover {
            color: #2DC079;
        }
        &.current {
            color: #2DC079;
        }
        &.single {
            background: unset;
            color: #333333;
            text-align: left;
        }
    }
    .has-tow-level {
        display: none;
    }
    .selected {
        display: block;
    }
    .noSelected {
        display: none !important;
    }
    .close {
        display: block;
    }
</style>