<template>
    <div class="examData-wrapper">
        <div class="top-tab" v-if="opId">
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane label="热门商品" name="studentTrainCenterGoodsInfoHotGoods"></el-tab-pane>
                <el-tab-pane label="文字资料采编" name="studentTrainCenterGoodsInfoGoodsText"></el-tab-pane>
                <!-- <el-tab-pane label="热门关键词" name="studentTrainCenterGoodsInfoHotKeyword"></el-tab-pane> -->
            </el-tabs>
            <div class="right">
                <!-- <el-button type="primary" @click="downloadMaterial">下载素材</el-button> -->
            </div>
        </div>
        <div class="top-tab" v-else>
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane label="热门商品" name="studentExamingGoodsInfoHotGoods"></el-tab-pane>
                <el-tab-pane label="文字资料采编" name="studentExamingGoodsInfoGoodsText"></el-tab-pane>
                <!-- <el-tab-pane label="热门关键词" name="studentExamingGoodsInfoHotKeyword"></el-tab-pane> -->
            </el-tabs>
            <div class="right">
                <!-- <el-button type="primary" @click="downloadMaterial">下载素材</el-button> -->
            </div>
        </div>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    import { stuOpGetExamInfo } from '@/utils/apis.js'
    export default {
        name: "GoodsInfoLayout",
        data() {
            return {
                opId: this.$route.query.id || null,
                tabName: ''
            }
        },
        created() {
            this.tabName = this.$route.meta.tabName;
        },
        methods: {
            //切换
            toggleTab(tab) {
                if (this.opId) {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            goodsInfoId: this.$route.query.goodsInfoId,
                            id: this.opId,
                            course_id: this.$route.query.course_id,
                            exam_module_id: this.$route.query.exam_module_id
                        }
                    })
                } else {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            goodsInfoId: this.$route.query.goodsInfoId,
                            exam_module_id: this.$route.query.exam_module_id
                        }
                    })
                }
            },
            //下载素材
            downloadMaterial() {
                let param = {
                    type: 'goods_info_collect'
                }
                if (this.opId) {
                    param.op_id = this.opId;
                }
                stuOpGetExamInfo(param).then((res) => {
                    window.location.href = res.data.file_download;
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .examData-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }
    .top-tab {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #F1F5FF;
        }
        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }
    .tabs-full {
        width: 1%;
        flex: 1;
        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }
            .el-tabs__header {
                margin-bottom: 0;
            }
            .el-tabs__active-bar {
                display: none;
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;
                &.is-active {
                    background: #E7F6EF;
                }
            }
            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }
</style>