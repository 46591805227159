import Vue from 'vue'
import Vuex from 'vuex'
import {epKwList, epKwsData, SDeppList} from "@/utils/searchPromotion";
import {errMsg} from "@/utils/common";
import VuexPersistence from "vuex-persist"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    reducer: (state) => ({
        amount: state.amount,
        count: state.count,
        moduleTemplate: state.moduleTemplate,
        totalCost: state.totalCost,
        form: state.form,
        moduleId: state.moduleId
    })

})

export default new Vuex.Store({
    getters: {
        indexMenu: state => state.indexMenu,
        adminMenu: state => state.adminMenu,
        schoolMenu: state => state.schoolMenu,
        teacherMenu: state => state.teacherMenu,
        judgesMenu: state => state.judgesMenu,
        studentMenu: state => state.studentMenu,
        evaluationMenu: state => state.evaluationMenu,
        studentExamData: state => state.studentExamData,
        operationBasicData: state => state.operationBasicData,
        operationStepTwoData: state => state.operationStepTwoData,
        keywordLists: state => state.keywords,
        keywordData: state => state.keywordsData,
        peopleOrientList: state => state.peopleOrients,
        amountValue: state => state.amount,
        moduleTemplateValue: state => state.moduleTemplate,
        countValue: state => state.count,
        totalCostValue: state => state.totalCost,
        formValue: state => state.form,
        budgetValue: state => state.budget,
        moduleId: state => state.moduleId,
    },
    state: {
        undone: 0,
        indexMenu: [],
        adminMenu: [],
        schoolMenu: [],
        teacherMenu: [],
        judgesMenu: [],
        studentMenu: [],
        evaluationMenu: [],
        studentExamData:{},
        evaluationStudentExamData:{},
        operationBasicData:{},
        operationStepTwoData:{},
        keywords: [],
        keywordsData: [],
        peopleOrients: [],
        amount: 0,
        moduleTemplate: [],
        count: 3,
        totalCost: 0,
        form: null,
        budget: null,
        moduleId: 0,
    },
    mutations: {
        SET_INDEX_ROUTES: (state, routes) => {
            state.indexMenu = routes
        },
        SET_ADMIN_ROUTES: (state, routes) => {
            state.adminMenu = routes
        },
        SET_SCHOOL_ROUTES: (state, routes) => {
            state.schoolMenu = routes
        },
        SET_TEACHER_ROUTES: (state, routes) => {
            state.teacherMenu = routes
        },
        SET_JUDGES_ROUTES: (state, routes) => {
            state.judgesMenu = routes
        },
        SET_STUDENT_ROUTES: (state, routes) => {
            state.studentMenu = routes
        },
        SET_EVALUATION_ROUTES: (state, routes) => {
            state.evaluationMenu = routes
        },
        setStudentExamData: (state, payload) => {
            state.studentExamData = payload
        },
        setEvaluationStudentExamData: (state, payload) => {
            state.evaluationStudentExamData = payload
        },
        setOperationBasicData: (state, payload) => {
            state.operationBasicData = payload
        },
        setOperationStepTwoData: (state, payload) => {
            state.operationStepTwoData = payload
        },
        setKeyWordList: (state, playload) => {
            state.keywords = playload;
        },
        setKeyWordData: (state, playload) => {
            state.keywordsData = playload;
        },
        setPeopleOrientList: (state, playload) => {
            state.peopleOrients = playload;
        },
        setAmount: (state, payload) => {
            state.amount = payload;
        },
        setModuleTemplate: (state, payload) => {
            state.moduleTemplate = payload
        },
        setCount: (state, payload) => {
            state.count = payload;
        },
        setTotalCost: (state, payload) => {
            state.totalCost = payload;
        },
        setForm: (state, payload) => {
            state.form = payload
        },
        setBudget: (state, payload) => {
            state.budget = payload;
        },
        setModuleId: (state, payload) => {
            state.moduleId = payload;
        }
    },
    actions: {
        setModuleIdValue({commit}, val) {
            commit("setModuleId", val)
        },
        generateIndexRoutes({ commit }, indexRoutes) {
            commit('SET_INDEX_ROUTES', indexRoutes)
        },
        generateAdminRoutes({ commit }, adminRoutes) {
            commit('SET_ADMIN_ROUTES', adminRoutes)
        },
        generateSchoolRoutes({ commit }, schoolRoutes) {
            commit('SET_SCHOOL_ROUTES', schoolRoutes)
        },
        generateTeacherRoutes({ commit }, teacherRoutes) {
            commit('SET_TEACHER_ROUTES', teacherRoutes)
        },
        generateJudgesRoutes({ commit }, judgesRoutes) {
            commit('SET_JUDGES_ROUTES', judgesRoutes)
        },
        generateStudentRoutes({ commit }, studentRoutes) {
            commit('SET_STUDENT_ROUTES', studentRoutes)
        },
        generateEvaluationRoutes({ commit }, evaluationRoutes) {
            commit('SET_EVALUATION_ROUTES', evaluationRoutes)
        },
        setBudgetData({commit}, value) {
            commit("setBudget", value)
        },
        setKeyWordList: ({ commit }) => {
            new Promise((resolve, reject) => {
                epKwList({}).then((res) => {
                    if (res.code === 200) {
                        commit('setKeyWordList', res.data.data);
                        resolve(res.data.data);
                    } else {
                        commit('setKeyWordList', []);
                    }
                }).catch(e => {
                    errMsg(e)
                })
            }).then(res => {
                let ids = [];
                _.each(res, (item) => {
                    ids.push(item.keyword_id);
                });
                if (ids.length === 0) {
                    return;
                }
                let param = {
                    nopage: 1,
                    id: ids.join(',')
                }
                epKwsData(param).then((resp) => {
                    if (resp.code === 200) {
                        commit('setKeyWordData', resp.data.list);
                    }
                }).catch(err => {
                    errMsg(err)
                })

            }).catch(err => {
                console.log(err);
            })

        },
        setPeopleOrientList: ({ commit }) => {
            SDeppList().then((res) => {
                if (res.code === 200) {
                    commit('setPeopleOrientList', res.data);
                } else {
                    commit('setPeopleOrientList', []);
                }
            }).catch(err => errMsg(err))
        },
        setAmountValue({commit}, value) {
            commit("setAmount", value)
        },
        setModuleTemplates({commit}, value) {
            commit("setModuleTemplate", value)
        },
        setCountValue({commit}, value) {
            commit("setCount", value)
        },
        setTotalCostValue({commit}, value) {
            commit("setTotalCost", value)
        },
        setFormData({commit}, value) {
            commit("setForm", value)
        }
    },
    modules: {
    },
    plugins: [vuexLocal.plugin]
})
