<template>
    <div class="count-down" :class="is_exam ? 'is-exam' : ''">
        <i class="iconfont">&#xe662;</i>
        <span style="margin-right: 10px;">{{timeText}}</span>
        <span>{{countDownList}}</span>
    </div>
</template>

<script>
    import {examStuExamPage} from "../../utils/apis";

    export default {
        name: "CountDown",
        props: {
          timeObj: Object
        },
        data() {
            return {
                //倒计时
                timeText: '',
                countDownList: '00 : 00 : 00 : 00',
                actBeginTime: '',
                actEndTime: '',
                is_exam: false,
            }
        },
        mounted() {
            if (this.$route.path === '/student/exam') {
                this.is_exam = true;
            }
            if (this.$route.name === 'studentWait') {
              this.startExam()
            }
            let timeSet = setInterval(()=>{
              if(this.timeObj.end_time !== null){
                this.getStudentComeInfo();
                this.countDown();
                clearInterval(timeSet);
              }
            },500)
        },
        methods: {
            //获取比赛信息
            getStudentComeInfo() {
              this.actBeginTime = this.timeObj.begin_time;
              this.actEndTime = this.timeObj.end_time;
            },
            //倒计时
            timeFormat(param) {
                return param < 10 ? '0' + param : param;
            },
            startExam() {
              if (localStorage.getItem('exam_form') === '0') {
                  return this.$router.push({ path: '/student/evaluation/examing/theoryTest/index', query: {id: this.$route.query.id}})
              }
              examStuExamPage().then(res => {
                console.log(res.data,"res");
                if (res.data.begin_time && res.data.begin_time > res.data.now + 10) {
                  this.$router.push('/student/wait')
                  return;
                }
                localStorage.setItem('exam_type', parseInt(res.data.type));
                localStorage.setItem('end_time', res.data.end_time);
                // 四级
                if (res.data.exam_grade === 1) {
                  if (res.data.goods_info_collect_name) {
                    this.$router.push('/student/examing/goodsInfo/index')
                    return
                  }
                  if (res.data.shop_decoration_name) {
                    this.$router.push('/student/examing/onlineShopDecoration/index')
                    return;
                  }
                  if (res.data.shop_management_name) {
                    this.$router.push('/student/examing/onlineShopManagement/index');
                    return;
                  }
                  if (res.data.network_promotion_name) {
                    this.$router.push('/student/examing/webPromotion/index');
                    return;
                  }
                  if (res.data.store_customer_name) {
                    this.$router.push('/student/examing/onlineShopCustomerService/index');
                    return;
                  }
                  if (res.data.data_capture_clean_name) {
                    this.$router.push('/student/examing/ECommerceDataCollectionAndCleaning/index');
                    return;
                  }
                } else if (res.data.exam_grade === 24) { // 三级
                  if (res.data.store_visual_design_name) {
                    this.$router.push('/student/exam/onlineShopVisualDesign/index');
                    return;
                  }
                  if (res.data.network_marketing_name) {
                    this.$router.push('/student/exam/internetMarketing/index');
                    return;
                  }
                  if (res.data.online_transaction_management_name) {
                    this.$router.push('/student/exam/onlineTradeManage/index');
                    return;
                  }
                  if (res.data.store_customer_service_level_three_name) {
                    this.$router.push('/student/exam/internetCustomerService/index');
                    return
                  }
                  if (res.data.data_analysis_application_name) {
                    this.$router.push('/student/exam/ECommerceDataAnalysisAndApplication/index');
                    return
                  }
                }else if(res.data.exam_grade === 74){
                  if (res.data.data_analysis_application_name) {
                    this.$router.push('/student/exam/ECommerceDataAnalysisAndApplication/index');
                    return
                  }
                }

              })
            },
            countDown() {
                var interval = setInterval(() => {
                    // 获取当前时间
                    let newTime = Math.round(new Date() / 1000);
                    //获取开始时间，结束时间
                    let endTime = this.actEndTime;
                    let startTime = this.actBeginTime;
                    let obj = null;
                    //开始比赛
                    if (newTime - startTime > 0) {
                        this.timeText = '距离考试结束还有：';
                        // 如果活动未结束，对时间进行处理
                        if (endTime - newTime > 0) {
                            let time = endTime - newTime;
                            // 获取天、时、分、秒
                            let day = parseInt(time / (60 * 60 * 24));
                            let hou = parseInt(time % (60 * 60 * 24) / 3600);
                            let min = parseInt(time % (60 * 60 * 24) % 3600 / 60);
                            let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60);
                            obj = {
                                day: this.timeFormat(day),
                                hou: this.timeFormat(hou),
                                min: this.timeFormat(min),
                                sec: this.timeFormat(sec)
                            };
                        } else { // 活动已结束，全部设置为'00'
                            obj = {
                                day: '00',
                                hou: '00',
                                min: '00',
                                sec: '00'
                            };
                            clearInterval(interval);
                            this.$message({
                                type: 'error',
                                message: '考试时间到！停止作答！',
                                duration: '1000'
                            });
                            this.$router.push('/');
                            // this.$emit('update:endDialog', true)
                        }
                        this.countDownList = obj.day + ' : ' + obj.hou + ' : ' + obj.min + ' : ' + obj.sec + '';

                        //比赛或者训练结束时，结束推广
                        if (this.countDownList === '00 : 00 : 00 : 00') {
                            let formData = new FormData();
                            // this.$http.axiosPost(this.$api.storedrainage_endoperation, formData, (res) => {
                            //     if (res.code === 200) {
                            //         this.$router.push('/student/index');
                            //     } else {
                            //         this.$message.warning(res.msg);
                            //     }
                            // }, (err) => {
                            //     console.log(err);
                            // })
                        }
                    } else { //还未开始比赛
                        this.timeText = '距离考试开始还有：';
                        let time = startTime - newTime;
                        //当时间差为0时，刷新页面
                        if (time === 0){
                            this.startExam()
                            // window.location.reload();
                        }
                        // 获取天、时、分、秒
                        let day = parseInt(time / (60 * 60 * 24));
                        let hou = parseInt(time % (60 * 60 * 24) / 3600);
                        let min = parseInt(time % (60 * 60 * 24) % 3600 / 60);
                        let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60);
                        obj = {
                            day: this.timeFormat(day),
                            hou: this.timeFormat(hou),
                            min: this.timeFormat(min),
                            sec: this.timeFormat(sec)
                        };
                        this.countDownList = obj.day + '：' + obj.hou + '：' + obj.min + '：' + obj.sec + '';
                    }
                }, 1000);
            }   
        }
    }
</script>

<style scoped lang="scss">
    .count-down {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #333;
        font-weight: 500;
        i {
            color: #fff;
            font-size: 20px;
            margin-right: 10px;
        }
    }
    .is-exam {
        color: #333;
        i {
            color: #2DC079;
        }
    }
</style>