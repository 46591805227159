<template>
    <div class="examData-wrapper">
        <div class="top-tab">
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane label="证书管理" name="evaluationCenterCertificateManagementIndex"></el-tab-pane>
                <el-tab-pane label="证书模板" name="evaluationCenterCertificateManagementModel"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    export default {
        name: "CertificateLayout",
        data() {
            return {
                tabName: 'evaluationCenterCertificateManagementIndex'
            }
        },
        created() {
            this.tabName = this.$route.meta.tabName;
        },
        methods: {
            //切换
            toggleTab(tab) {
               this.$router.push({name: tab.name,})
            },
        }
    }
</script>

<style scoped lang="scss">
    .examData-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }
    .top-tab {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #F1F5FF;
        }
        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }
    .tabs-full {
        width: 1%;
        flex: 1;
        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }
            .el-tabs__header {
                margin-bottom: 0;
            }
            .el-tabs__active-bar {
                display: none;
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;
                &.is-active {
                    background: #E7F6EF;
                }
            }
            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }

</style>