<template>
    <div class="right-wrapper">
        <div class="right-header">
            <div class="header-tab" v-if="opId">
                <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                    <el-tab-pane label="行业数据" name="studentTrainCenterStartTrainingIndustryMarket"></el-tab-pane>
                    <el-tab-pane label="商品榜单" name="studentTrainCenterStartTrainingCommodityListIndex"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="header-tab" v-else>
                <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                    <el-tab-pane label="行业数据" name="studentProcurementIndustryMarket"></el-tab-pane>
                    <el-tab-pane label="商品榜单" name="studentPromotionCommodityListIndex"></el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <el-scrollbar class="right-content" :native="false">
            <div class="right-wrapper-content">
                <router-view/>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    export default {
        name: "dataAnalysisLayout",
        data() {
            return {
                opId: this.$route.query.op_id || null,
                tabName: ''
            }
        },
        created() {
            this.tabName = this.$route.meta.tabName;
        },
        methods: {
            //切换
            toggleTab(tab) {
                if (this.opId) {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            op_id: this.opId,
                            course_id: this.$route.query.course_id,
                            exam_module_id: this.$route.query.exam_module_id
                        }
                    })
                } else {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            exam_module_id: this.$route.query.exam_module_id
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .commodityTab {
        ::v-deep .el-tabs__header {
            margin: 0;
            .el-tabs__nav-wrap {
                padding: 0 30px;
            }
            .el-tabs__active-bar {
                background-color: #FD4446;
            }
            .el-tabs__item.is-active {
                color: #FD4446;
            }
            .el-tabs__nav-wrap::after {
                height: 1px;
                background-color: #D1D2DB;
            }
        }
        ::v-deep .el-tabs__item {
            height: 80px;
            line-height: 80px;
            font-size: 20px;
            &:hover {
                color: #FD4446;
            }
        }
    }

    .right-wrapper {
        /*width: 1%;*/
        /*flex: 1;*/
        display: flex;
        flex-direction: column;
        .right-header {
            /*height: 80px;*/
            background: #fff;
            /*border-bottom: 1px solid #D1D2DB;*/
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .header-tab {
                height: 100%;
                position: absolute;
                left: 30px;
                display: flex;
                align-items: center;
                font-weight: 500;
                color: #333333;
                top:-32px;
                .activeSelect {
                    a {
                        display: block;
                        height: 100%;
                        color: #FD4446 !important;
                        border-bottom: 2px solid #FD4446 !important;
                    }
                }
                .item-tab {
                    height: 100%;
                    margin-right: 25px;
                    cursor: pointer;
                    line-height: 81px;
                    border-bottom: 2px solid transparent;
                    font-size: 20px;
                    a {
                        display: block;
                        color: #333333;
                        &:hover {
                            color: #FD4446;
                        }
                    }
                }
            }
        }
        ::v-deep .right-content {
            height: 1%;
            flex: 1;
            .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    min-width: 900px;
                    /*height: 100%;*/
                }
                .right-wrapper-content {
                    padding: 20px 30px 20px 40px;
                    background: #F7F9FB;
                    box-sizing: border-box;
                }
            }
        }
        ::v-deep .el-tabs__item.is-active{
            color: #FD4446
        }
        ::v-deep .el-tabs__active-bar{
            background-color: #FD4446;
        }
        ::v-deep .el-tabs__item{
            height: 60px;
            line-height: 60px;
        }
        ::v-deep .el-tabs__nav-wrap::after{
            background-color: transparent;
        }
        ::v-deep .el-tabs__header{
            margin: 0;
        }
        ::v-deep .el-tabs__item{
            font-size: 20px;
        }
        ::v-deep .el-tabs__item:hover {
            color: #FD4446;
            cursor: pointer;
        }
    }
</style>