import http from "./request";
export const searchPlanList = (param) => http.get("search/plan", {params: param})

export const epGetSB = () => http.get("ep/getSB");

export const SDepList = (param) => http.get("ep/epList", {params: param});

export const delEP = (param) => http.get("ep/delEP", {params: param})
export const editEP = (param) => http.get("ep/editEP", {params: param});
export const epGoods = (param = {}) => http.get("ep/goods", {params: param});
export const epKwModule = () => http.get("ep/kwModule");
export const editPTD = (param) => http.get("ep/editPTD", {params: param});
export const epKwList = (param) => http.get("ep/epkList", {params: param});
export const epKwsData = (param) => http.get("ep/kwsData", {params: param});
export const delEPK = (param) => http.get("ep/delEPK", {params: param});
export const addKw = (data) => http.post("ep/addK", data)
export const SDPlanList = () => http.get("ep/pnList");
export const keywordOfferRank = () => http.get("stuep/kwstoreoffer");
export const SDunList = (param) => http.get("ep/unList", {params: param});
export const SDeppList = (param= {}) => http.get("ep/eppList", {params: param});
export const peopleCrowd = (param) => http.post("ep/peopleCrowd", param);
export const stuPlanKeywords = (param) => http.get("stuep/keywordids", {params: param});
export const stuEndingCalc = () => http.get("stuEndingCalc");
export const getEPlanDetail = (param) => http.get("ep/getEPD", {params: param});
export const checkPlanName = (param) => http.get("ep/checkPN", {params: param})
export const kwsMain = (param = {}) => http.get("ep/kwsMain", {params: param})
export const epPeople = (param={}) => http.get("ep/peoples", {params: param})
export const ePlanKeywords = (param={}) => http.get("ep/keywords", {params: param});
export const getSD = (data={}) => http.post("ep/getSD", data)
export const saveEPlan = (data) => http.post("ep/save", data)
export const rankValue = (param) => http.get("ep/store_offer", {params : param});
export const peopleEstimate = (data) => http.post("ep/people_estimate", data)
export const stuEndingPromotion = (data) => http.post("stuep/ending", data)
export const planKeywordList = (param) => http.get("ep/plan_keywords", {params: param});
export const updateCrowd = (data) => http.post("ep/update_crowd", data);
export const dataAnalysisTime = (param) => http.get("ep/general_time_data", {params: param});
export const dataAnalysisArea = (param) => http.get("ep/general_area_data", {params: param});
export const dataAnalysisKeywords = (param) => http.get("ep/general_keyword_data", {params: param});
export const searchAnswerDetail = (param) => http.get("search/answer", {params: param});
