import { render, staticRenderFns } from "./livePromotionLayout.vue?vue&type=template&id=ff52772a&scoped=true&"
import script from "./livePromotionLayout.vue?vue&type=script&lang=js&"
export * from "./livePromotionLayout.vue?vue&type=script&lang=js&"
import style0 from "./livePromotionLayout.vue?vue&type=style&index=0&id=ff52772a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff52772a",
  null
  
)

export default component.exports